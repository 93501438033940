









































.error-icon {
  width: 120px;
  border-radius: 5px;
}
